import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Label, Header } from "components/core";
import successImg from "assets/images/success.svg";
import { GREY } from "config/colors";
import { changeLanguage } from "config/i18n";
import * as S from "./styles";

const Success = () => {
  const { t: translate } = useTranslation();

  useEffect(() => {
    changeLanguage(navigator.language);
  }, []);

  return (
    <S.Container>
      <Helmet>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          name="viewport"
        />
      </Helmet>
      <Header />
      <S.Content>
        <S.Image src={successImg} />
        <Label fontWeight="bold" fontSize={20} marginTop={40}>
          {translate("success-title")}
        </Label>
        <Label color={GREY} marginTop={16}>
          {translate("success-subtitle")}
        </Label>
      </S.Content>
    </S.Container>
  );
};

export default Success;
