import React from "react";
import SuccessPresentational from "components/presentations/Success";
import { GlobalStyle } from "components/core";

const Success = () => (
  <>
    <GlobalStyle />
    <SuccessPresentational />
  </>
);

export default Success;
